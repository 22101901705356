import React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const openedMixin = (theme) => ({
  width: 'fit-content',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position:'relative',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  position:'relative',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: 'fit-content',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position:'relative',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function DocBar(props) {
  const DrawerHeader = props.drawerHeader;
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const renderBigScreen = () => {
    return (
      <Drawer variant="permanent" open={props.open}>
        <DrawerHeader>
          {props.open ? (
            <IconButton onClick={props.closeBar}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton onClick={props.openBar}>
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        
        {props.open ? 
          (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                gap: 1.5,
                margin: '10px'
              }}
              onClick={() => props.setActiveTab(null)}
            >
              <Card
                key={'House'}
                sx={{
                  boxShadow: 'none',
                  '&:hover': { bgcolor: 'background.level1' },
                }}
              >
                <CardContent sx={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                  <HomeRoundedIcon />
                  <Typography level="title-md">Home</Typography>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '10px'
              }}
              onClick={() => props.setActiveTab(null)}
            >
              <IconButton>
                <HomeRoundedIcon />
              </IconButton>
            </Box>
          )
        }
        <List>
          {props.barList.map((tab, index) => (
            <ListItem key={tab.id} disablePadding sx={{ display: 'block', backgroundColor: props.activeTab.id === tab.id ? 'lightgray' : undefined }} onClick={() => props.setActiveTab(tab)} >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {tab.file === undefined ? (<EditNoteRoundedIcon />) : (<DescriptionRoundedIcon />)}
                </ListItemIcon>
                <ListItemText primary={tab.name} sx={{ opacity: props.open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    )
  }

  const renderSmallScreen = () => {
    return (
      <Box
        sx={{
          margin: '10px'
        }}
        onClick={() => props.setActiveTab(null)}
      >
        <Card
          key={'House'}
          sx={{
            boxShadow: 'none',
            '&:hover': { bgcolor: 'background.level1' },
          }}
        >
          <CardContent sx={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
            <HomeRoundedIcon />
            <Typography level="title-md">Home</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <div>
      {isXsScreen ? renderSmallScreen() : renderBigScreen()}
    </div>
  );
}