import React, {useState} from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import SnackbarNotification from '../primitives/Snackbar';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DialogBox from '../primitives/Dialog';
import InputForm from '../primitives/InputForm';
import LibraryAddRoundedIcon from '@mui/icons-material/LibraryAddRounded';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export default function SideBar(props) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [snackbar, setSnackbar] = useState({snackbarOpen: false, snackbarMessage: ''});

  const [isFormVisible, setIsFormVisible] = useState(false); // State to control form visibility

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is xs or smaller

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const handleSubmit = () => {
    if (name === '' || description === '') {
      setSnackbar({snackbarOpen: true, snackbarMessage: 'Please fill out all fields.'});
      return;
    }
    props.handleFormSubmit(name, description);
    setName('');
    setDescription('');
  }

  const handleSubmitForm = (formData) => {
    if (formData['project-name'] === undefined || formData['project-name'] === '' || formData['project-description'] === undefined || formData['project-description'] === '') {
      setSnackbar({snackbarOpen: true, snackbarMessage: 'Please fill out all fields.'});
      return;
    }
    props.handleFormSubmit(formData['project-name'], formData['project-description']);
    toggleFormVisibility();
  }

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const grid = {
    'grid-spacing': 3,
    fields: [
      {
        'formGrid': {xs: 12, md: 12 },
        'isLabel': true,
        'formLabel': { htmlFor: "project-name", required: true, label: 'Project Name' },
        'outlinedInput': { id: "project-name", name: "project-name", type: "name", placeholder: "Webber 1", autoComplete: "project name", required: true }
      },
      {
        'formGrid': {xs: 12, md: 12 },
        'isLabel': true,
        'formLabel': { htmlFor: "project-description", required: true, label: 'Project Description' },
        'outlinedInput': { id: "project-description", name: "project-description", type: "project-description", placeholder: "For Client XYZ", autoComplete: "project description", required: true }
      },
    ],
    actions: [
      {
        'label': 'Submit',
        'startIcon': null,
        'endIcon': <LibraryAddRoundedIcon />,
        'onClick': (formData) => handleSubmitForm(formData) 
      },
    ],
    'style': {
        'formLabel': {
            marginBottom: '8px',
            borderRadius: '10px',
        },
        'outlinedInput': {
            borderRadius: '10px',
            backgroundColor: 'rgba(234, 240, 245, 0.4)'
        }
    }
  }

  const renderSidebar = () => (
    <React.Fragment>
      <Sheet
          sx={{
            borderRadius: 'md',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: 'calc(100vh - 110px)',
            overflow: 'auto',
            width: '20rem',
            boxShadow: '0 0 50px rgba(0, 0, 0, 0.2)',
            top: '20px',
            left: '20px',
            bottom: '20px',
            marginBottom: '20px'
          }}
        >
          <DialogTitle>Graphic Design</DialogTitle>
          <Divider sx={{ mt: 'auto' }} />
          <DialogContent sx={{ gap: 2 }}>
            <FormControl>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                  gap: 1.5,
                }}
              >
                <Card
                  key={'House'}
                  sx={{
                    boxShadow: 'none',
                    '&:hover': { bgcolor: 'background.level1' },
                  }}
                >
                  <CardContent sx={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={handleSubmit}>
                    <CreateNewFolderRoundedIcon />
                    <Typography level="title-md">Create New Project</Typography>
                  </CardContent>
                </Card>
              </Box>
              <Grid container spacing={3}>
                <FormGrid item xs={12} sx={{marginTop: '10px'}}>
                  <FormLabel htmlFor="projectName" required sx={{margin: '5px'}}>
                    Project Name
                  </FormLabel>
                  <OutlinedInput
                    id="projectName"
                    name="projectName"
                    type="projectName"
                    placeholder="Try Webber1"
                    autoComplete="project-name"
                    value={name}
                    required
                    onChange={handleNameChange}
                  />
                </FormGrid>
                <FormGrid item xs={12}>
                  <FormLabel htmlFor="projectDescription" required sx={{margin: '5px'}}>
                    Project Description
                  </FormLabel>
                  <TextField
                    id="projectDescription"
                    name="projectDescription"
                    placeholder="Keep within 150 words"
                    autoComplete="project-description"
                    required
                    multiline
                    rows={4} // You can adjust the number of rows as needed
                    variant="outlined"
                    value={description}
                    fullWidth
                    onChange={handleDescriptionChange}
                  />
                </FormGrid>
              </Grid>
            </FormControl>
          </DialogContent>
        </Sheet>
    </React.Fragment>
  );


  return (
    <React.Fragment>
      {isXsScreen ? (
        <React.Fragment>
          <Card
            key={'House'}
            sx={{
              boxShadow: 'none',
              '&:hover': { bgcolor: 'background.level1' },
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={toggleFormVisibility}>
              <CreateNewFolderRoundedIcon />
              <Typography level="title-md">Create New Project</Typography>
            </CardContent>
          </Card>
          <DialogBox open={isFormVisible} onClose={toggleFormVisibility} title={"Add New Project"} children={<InputForm grid={grid} />} />
        </React.Fragment>
      ) : (
        renderSidebar()
      )}
      <SnackbarNotification snackbarOpen={snackbar.snackbarOpen} snackbarMessage={snackbar.snackbarMessage} setSnackbar={setSnackbar}/>
    </React.Fragment>
  );
}