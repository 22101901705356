import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DialogBox = (props) => {
    const { open, onClose, title, children } = props;
  return (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            {title}
            <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {children}
        </DialogContent>
    </Dialog>
  );
};

export default DialogBox;