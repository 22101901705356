import React, { useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import "../../../styles/main.css";

export default function CustomFileUpload(props) {
    const toast = useRef(null);

    const onSelect= (e) => {
        console.log(e.files);
        const file = e.files[0];
        props.handleChange({target: {name: props.name, value: file}});
    };
        
    return (
        <div className="card flex justify-content-center file-upload-container" style={{ border: '1px solid black', borderRadius: '4px', backgroundColor: 'rgba(234, 240, 245, 0.4) !important', display: 'inline-block', width: 'fit-content'}} >
            <Toast ref={toast}></Toast>
            <FileUpload 
                mode="basic"
                name="demo[]"
                accept={props.acceptType}
                maxFileSize={props.maxFileSize}
                onSelect={onSelect}
                multiple={props.multiple}
                style={{...props.style}}
            />
        </div>  
    )
}