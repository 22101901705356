import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import React from 'react';
import ImageViewer from './imageViewer';
import CommentEditor from '../../../../primitives/CommentEditor';
import Activity from './Activity';
import {v4 as uuidv4} from 'uuid';
import { useSelector } from 'react-redux'
import { Avatar, Divider } from '@mui/material';
import { createComment, deleteComment } from '../../../../../../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const client = generateClient();
const drawerWidth = 260;
export default function Version(props) {
  // const versionData = props.verisionData;
  const userInfo = useSelector(state => state.user);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onSaveComment = async (_, comment) => {
    console.log(comment);
    try {
      const commentData = await client.graphql({
        query: createComment,
        variables: { 
          input: {
            id: uuidv4(),
            comment: comment,
            versionId: props.activeVersion.id,
            userName: userInfo.signInDetails.loginId
          }
        }
      });
      props.addComment(commentData.data.createComment);
    } catch (error) {
      console.log(error);
    }
  }

  const onEditComment = (comment) => {
    console.log(comment);
  }

  const onDeleteComment = async (comment) => {
    console.log(comment);
    const commentDetails = {
      id: comment.id
    };
    
    try {
      await client.graphql({
        query: deleteComment,
        variables: { input: commentDetails }
      });

      props.deleteComment(comment);
    } catch (error) {
      console.log(error);
    } 
  }


  const renderBigScreen = () => {
    return (
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }}}
      >
        <CardContent sx={{marginLeft: '20%', marginRight: '20%', paddingLeft: '0px', paddingRight: '0px'}}>
          <Typography gutterBottom variant="h5" component="div" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {'Version #' + props.activeVersion.versionNo + ": " + props.activeVersion.versionName}
          </Typography>
        </CardContent>

        <ImageViewer image={props.activeVersion.outputURL} altText={props.activeVersion.versionName} />
        
        <CardContent sx={{padding:'0px', marginBottom: '10px'}}>
          <CardActions sx={{marginLeft: '20%', marginRight: '20%', justifyContent:'end'}}>
            <Button 
              variant="outlined"
              startIcon={<DownloadRoundedIcon />}
              sx={{
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '10px',
                paddingRight: '10px',
                textTransform: 'none'
              }}
              onClick={props.downloadProjectFiles}
            > Project Files </Button>
          </CardActions>
          <Typography variant="body2" color="text.secondary" sx={{marginLeft: '20%', marginRight: '20%', paddingLeft: '0px', paddingRight: '0px', textAlign: 'left', fontWeight: 'bold'}}>
            Commit Message
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{marginLeft: '20%', marginRight: '20%', paddingLeft: '0px', paddingRight: '0px', textAlign: 'left'}}>
            {props.activeVersion.commitMsg}
          </Typography>
        </CardContent>
        <Box>
          <Divider sx={{marginLeft: '20%', marginRight: '20%', marginTop: '20px', marginBottom: '10px'}} />
          <CardContent sx={{padding:'0px', marginBottom: '10px', display: 'flex', justifyContent: 'left', marginLeft: '20%', marginRight: '20%' }}>
              <Avatar sx={{ width: 30, height: 30 }}/>
              <Typography variant="body2" color="text.secondary" sx={{ paddingLeft: '10px', paddingRight: '0px', textAlign: 'left', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                  Activity
              </Typography>
          </CardContent>
          <CommentEditor onSaveComment={onSaveComment} isExpanded={false} note={{value: ''}} clearCommentOnSave={true} />
          <Activity comments={props.activeVersion.comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))} onEditComment={onEditComment} onDeleteComment={onDeleteComment} initialValue={''} clearCommentOnSave={true} />
        </Box>
      </Box>
    )
  }

  const renderSmallScreen = () => {
    return (
      <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }}}
    >
      <CardContent sx={{marginLeft: '10%', marginRight: '10%', paddingLeft: '0px', paddingRight: '0px'}}>
        <Typography gutterBottom variant="h5" component="div" sx={{display: 'flex', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {'Version #' + props.activeVersion.versionNo + ": " + props.activeVersion.versionName}
        </Typography>
      </CardContent>

      <ImageViewer image={props.activeVersion.outputURL} altText={props.activeVersion.versionName} />
      
      <CardContent sx={{padding:'0px', marginBottom: '10px'}}>
        <CardActions sx={{marginLeft: '10%', marginRight: '10%', justifyContent:'end'}}>
          <Button 
            variant="outlined"
            startIcon={<DownloadRoundedIcon />}
            sx={{
              paddingTop: '0px',
              paddingBottom: '0px',
              paddingLeft: '10px',
              paddingRight: '10px',
              textTransform: 'none'
            }}
            onClick={props.downloadProjectFiles}
          > Project Files </Button>
        </CardActions>
        <Typography variant="body2" color="text.secondary" sx={{marginLeft: '10%', marginRight: '10%', paddingLeft: '0px', paddingRight: '0px', textAlign: 'left', fontWeight: 'bold'}}>
          Commit Message
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{marginLeft: '10%', marginRight: '10%', paddingLeft: '0px', paddingRight: '0px', textAlign: 'left'}}>
          {props.activeVersion.commitMsg}
        </Typography>
      </CardContent>
      <Box>
        <Divider sx={{marginLeft: '10%', marginRight: '10%', marginTop: '20px', marginBottom: '10px'}} />
        <CardContent sx={{padding:'0px', marginBottom: '10px', display: 'flex', justifyContent: 'left', marginLeft: '10%', marginRight: '10%' }}>
            <Avatar sx={{ width: 30, height: 30 }}/>
            <Typography variant="body2" color="text.secondary" sx={{ paddingLeft: '10px', paddingRight: '0px', textAlign: 'left', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                Activity
            </Typography>
        </CardContent>
        <CommentEditor onSaveComment={onSaveComment} isExpanded={false} note={{value: ''}} clearCommentOnSave={true} />
        <Activity comments={props.activeVersion.comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))} onEditComment={onEditComment} onDeleteComment={onDeleteComment} initialValue={''} clearCommentOnSave={true} />
      </Box>
    </Box>
    )
  }

  return (
      <Box sx={{ display: 'flex' }}>
        {isXsScreen ? renderSmallScreen() : renderBigScreen()}
      </Box>
  ); 
}