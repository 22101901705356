import React, { useState , useEffect } from 'react';
import { SpeedDial, Drawer, Tooltip } from '@mui/material';
import { Box, Divider, Typography } from "@mui/material";
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { generateClient } from "aws-amplify/api";
import { listFeatureSuggestions } from '../../../graphql/queries';
import { createFeatureSuggestion, updateFeatureSuggestion } from '../../../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const client = generateClient();
export default function QuickDial() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [newSuggestion, setNewSusggestion] = useState('');

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (drawerOpen) {
            fetchSuggestions();
        }

    }, [drawerOpen]);

    const fetchSuggestions = async () => {
        const suggestionData = await client.graphql({
            query: listFeatureSuggestions,
        })

        let suggestionList = suggestionData.data.listFeatureSuggestions.items;
        suggestionList = suggestionList.sort((a,b) => b.votes - a.votes);
        setSuggestions(suggestionList);
    }

    const handleUpvote = async (suggestion) => {
        console.log('upvoting suggestion with id: ', suggestion)
        await client.graphql({
            query: updateFeatureSuggestion,
            variables: {
                input: {
                    id: suggestion.id,
                    votes: suggestion.votes + 1
                }
            }
        });

        let suggestionList = suggestions.map(s => {
            if (s.id === suggestion.id) {
                return { ...s, votes: s.votes + 1 };
            }
            return s;
        });
        suggestionList = suggestionList.sort((a,b) => b.votes - a.votes);
        setSuggestions(suggestionList);
    }

    const handleSubmit = async () => {
        console.log('submitting suggestion: ', newSuggestion);
        await client.graphql({
            query: createFeatureSuggestion,
            variables: {
                input: {
                    id: uuidv4(),
                    suggestion: newSuggestion,
                    votes: 0
                }
            }
        })
        fetchSuggestions();
        setNewSusggestion('');
    }

    return (
        <div >
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={
                    <Tooltip title="New Features">
                        <IconButton>
                            <TipsAndUpdatesIcon sx={{ color: 'white', marginLeft: '3px' }} />
                        </IconButton>
                    </Tooltip>
                }
                onClick={toggleDrawer}
            />
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer}
                sx = {{
                    margin: '10px',
                    width: 'auto',
                    '& .MuiDrawer-paper': {
                        height: '50%',
                        top: 'auto',
                        bottom: 0,
                        width: 'auto',
                        margin: '10px',
                        borderRadius: '10px'
                    },
                }}
            >
                {/* Content of the drawer */}
                <Box
                    sx= {{
                        marginTop: '20px',
                        marginBlock: '20px',
                        width: 350
                    }}
                >
                    < Grid container spacing={3} >
                        <Box
                            sx={{
                                position: 'sticky',
                                top: 0,
                                width: '100%',
                                padding: '20px',
                                marginLeft: '24px',
                                boxSizing: 'border-box',
                                zIndex: 1,
                                background: 'white',
                                borderBottom: 'solid 1px black',
                            }}
                        >
                            <FormLabel htmlFor="feature-suggest" sx={{ marginBottom: '8px', borderRadius: '10px', textAlign: 'left', marginLeft: '24px', fontSize: '18px' }} key={'formLabel'}>Feature Suggestions</FormLabel>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'calc(100% - 60px)',
                                overflowY: 'auto',
                                width: '100%',
                                marginLeft: '15px',
                                marginRight: '10px',
                                paddingBottom: '77px'
                            }}
                        >
                            { suggestions.map((suggestion, index) => {
                                return (
                                    <Box
                                        sx={{
                                            margin: '5px',
                                            marginTop: '10px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                marginLeft: '24px',
                                                marginRight: '10px'
                                            }}
                                        >
                                            <FormLabel htmlFor="sugestion" sx={{ marginBottom: '8px', borderRadius: '10px', textAlign: 'left' }} key={'formLabel'}>{suggestion.suggestion}</FormLabel>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <IconButton onClick={() => handleUpvote(suggestion)} >
                                                <ThumbUpOffAltOutlinedIcon sx={{ color: 'black' }} />
                                            </IconButton>
                                            <Typography sx={{ paddingTop: '4px', marginRight: '7px', letterSpacing: 1 }}>{suggestion.votes}</Typography>
                                        </Box>
                                        <Divider />
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box
                            sx={{
                                position: 'fixed',
                                bottom: '10px',
                                width: '350px',
                                padding: '10px',
                                marginLeft: '24px',
                                boxSizing: 'border-box',
                                background: 'white',
                                borderBottomRightRadius: '10px',
                                borderBottomLeftRadius: '10px',
                                borderTop: 'solid 1px black',
                            }}
                        >
                            <OutlinedInput
                                fullWidth
                                placeholder="Type your suggestion here..."
                                value={newSuggestion}
                                endAdornment={
                                    <IconButton
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                }
                                onChange={(e) => {
                                    setNewSusggestion(e.target.value)
                                }}
                            />
                        </Box>
                    </Grid>
                </Box>
            </Drawer>
        </div>
    );

}