import React, { useState, useEffect } from "react";
import InputFormNoAction from "../../../primitives/InputFormNoAction";
import { Box, Button, Divider, Typography } from "@mui/material";
import CreatableSelect from 'react-select/creatable';
import FormLabel from '@mui/material/FormLabel';
import { listProjects, listMembers, listNotes, listVersions, listDocs, listComments } from '../../../../../graphql/queries';
import { updateProjects, updateMembers, createMembers, deleteNotes, deleteDocs, deleteProjects, deleteVersion, deleteComment } from "../../../../../graphql/mutations";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import LoadingMask from "../../../primitives/LoadingMask";
import { useSelector } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import { remove, list } from 'aws-amplify/storage';
import SnackbarNotification from "../../../primitives/Snackbar";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const client = generateClient();

export function Setting() {
    const [loading, setLoading] = React.useState(true);
    const [formData, setFormData] = React.useState({});
    const { id } = useParams();
    const [snackbar, setSnackbar] = React.useState({ snackbarOpen: false, snackbarMessage: '' });
    const userInfo = useSelector(state => state.user);
    const navigate = useNavigate();

    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let initialMembers = [];

    // Step 2: Create an onChange handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        setLoading(true);
        fetchProjectDetails()
        .then(() => {setLoading(false)})
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }, []);

    const fetchProjectDetails = async () => {
        const projectData = await client.graphql({
            query: listProjects,
            variables: {
                filter: {
                    id: {
                        eq: id
                    }
                }
            }
        });

        const project = projectData.data.listProjects.items[0];
        initialMembers = project.members;
        setFormData({
            'project-name': project.name,
            'project-description': project.description,
            'project-lead': project.lead,
            'project-member': initialMembers
        });
    }
    
    const handleSubmit = async () => {
        console.log(formData);
        setLoading(true);
        try {
            let projectMembers = [...formData['project-member']];
            if(!projectMembers.includes(formData['project-lead'])) {
                projectMembers.push(formData['project-lead']);
            }
            await client.graphql({
                query: updateProjects,
                variables: {
                    input: {
                        id: id,
                        name: formData['project-name'],
                        description: formData['project-description'],
                        lead: formData['project-lead'],
                        members: projectMembers
                    }
                }
            });

            for (const memberEmail of formData['project-member']) {
                let memberData = await client.graphql({
                    query: listMembers,
                    variables: {
                        filter: {
                            email: {
                                eq: memberEmail
                            }
                        }
                    }
                });
                console.log(memberData);
                let memberWrapper = memberData.data.listMembers.items;
                if (memberWrapper.length === 0) {
                    await client.graphql({
                        query: createMembers,
                        variables: { 
                          input: {
                            id: uuidv4(),
                            name: memberEmail,
                            email: memberEmail,
                            projects: [id]
                          }
                        }
                      })
                }else {
                    let member = memberWrapper[0];
                    if (!member.projects.includes(id)) {
                        await client.graphql({
                            query: updateMembers,
                            variables: {
                                input: {
                                    id: member.id,
                                    projects: [...member.projects, id]
                                }
                            }
                        });
                    }
                }
            };

            await fetchProjectDetails();
            setSnackbar({ snackbarOpen: true, snackbarMessage: 'Details updated successfully' });
        } catch (error) {
            console.log(error);
            setSnackbar({ snackbarOpen: true, snackbarMessage: 'Error updating details' });
        }
        setLoading(false);
    }

    const handleDeleteVersions = async () => {
        setLoading(true);
        try {
            await deleteVersions();
            setSnackbar({ snackbarOpen: true, snackbarMessage: 'Versions deleted successfully' });
        } catch (error) {
            console.log(error);
            setSnackbar({ snackbarOpen: true, snackbarMessage: 'Error deleting versions' });
        }
        setLoading(false);
    }

    const deleteFolder = async (folderPath) => {
        if (folderPath === '' || folderPath === undefined || folderPath === null) return;
        const result = await list({
            path: folderPath,
        });
        for (const item of result.items){
            await remove({
                path: item.path,
            });
            console.log('Deleted: ', item.path);
        };
    }


    const deleteVersions = async () => {
        const response = await client.graphql({
            query: listVersions,
            variables: { filter: { projectId: { eq: id } } },
        });

        for (const version of response.data.listVersions.items) {
            const commentsResponse = await client.graphql({
                query: listComments,
                variables: { filter: { versionId: { eq: version.id } } },
            });

            for (const comment of commentsResponse.data.listComments.items) {
                await client.graphql({
                    query: deleteComment,
                    variables: { input: { id: comment.id } },
                });
            }

            await remove({
                path: version.outputFilePath,
            });

            await deleteFolder(version.folderPath);

            await client.graphql({
                query: deleteVersion,
                variables: { input: { id: version.id } },
            });
        }
    }

    const deleteDocsFromProject = async () => {
        const docsResponse = await client.graphql({
            query: listDocs,
            variables: { filter: { projectId: { eq: id } } },
        });

        for (const doc of docsResponse.data.listDocs.items) {
            await remove({
                path: doc.file,
            });

            await client.graphql({
                query: deleteDocs,
                variables: { input: { id: doc.id } },
            });
        }
    }

    const deleteNotesWithFilter = async (filter) => {
        const response = await client.graphql({
            query: listNotes,
            variables: { filter: { projectId: { eq: id } } },
        });

        // Step 2: Filter out the note named "Note"
        let notesToDelete;
        if (filter) {
            notesToDelete = response.data.listNotes.items.filter(note => note.name !== "Note");
        } else {
            notesToDelete = response.data.listNotes.items;
        }
        
        // Step 3: Delete each filtered note
        for (const note of notesToDelete) {
            await client.graphql({
                query: deleteNotes,
                variables: { input: { id: note.id } },
            });
        }
    }

    const handleDeleteNotesAndDocs = async () => {
        setLoading(true);
        try {
            await deleteNotesWithFilter(true);
            await deleteDocsFromProject();
            
            setSnackbar({ snackbarOpen: true, snackbarMessage: 'Notes and Docs deleted successfully' });
        } catch (error) {
            console.log(error);
            setSnackbar({ snackbarOpen: true, snackbarMessage: 'Error deleting Notes and Docs' });
        }
        setLoading(false);
    }

    const handleDeleteProject = async () => {
        setLoading(true);
        try {

            await deleteNotesWithFilter(false);
            await deleteDocsFromProject();
            await deleteVersions();

            await client.graphql({
                query: deleteProjects,
                variables: {
                    input: {
                        id: id
                    }
                }
            });

            for (const memberEmail of formData['project-member']) {
                let memberData = await client.graphql({
                    query: listMembers,
                    variables: {
                        filter: {
                            email: {
                                eq: memberEmail
                            }
                        }
                    }
                });
                console.log(memberData);
                let memberWrapper = memberData.data.listMembers.items;
                if (memberWrapper.length !== 0) {
                    await client.graphql({
                        query: updateMembers,
                        variables: {
                            input: {
                                id: memberWrapper[0].id,
                                projects: memberWrapper[0].projects.filter(projectId => projectId !== id)
                            }
                        }
                    });
                }
            }

            setSnackbar({ snackbarOpen: true, snackbarMessage: 'Project deleted successfully' });
            navigate("/product");
        } catch (error) {
            console.log(error);
            setSnackbar({ snackbarOpen: true, snackbarMessage: 'Error deleting project' });
        }
        setLoading(false);
    }

    const projectDetails = {
        'grid-spacing': 3,
        fields: [
            {
                'formGrid': {xs: 12, md: 12 },
                'isLabel': true,
                'formLabel': { htmlFor: "project-name", required: true, label: 'Project Name' },
                'outlinedInput': { id: "project-name", name: "project-name", type: "name", placeholder: "Matilda-07", autoComplete: "project name", required: true, value: formData['project-name'] }
            },
            {
                'formGrid': {xs: 12, md: 12 },
                'isLabel': true,
                'formLabel': { htmlFor: "project-description", required: true, label: 'Project Description' },
                'outlinedInput': { id: "project-description", name: "project-description", type: "project-description", placeholder: "@Project M @Added Blue backdrop", autoComplete: "project-description", required: true, value: formData['project-description']}
            },           
        ],
        actions: [],
        'style': {
            'formLabel': {
                marginBottom: '8px',
                borderRadius: '10px',
                textAlign: 'left',
            },
            'outlinedInput': {
                borderRadius: '10px',
                backgroundColor: 'rgba(234, 240, 245, 0.4)'
            }
        }
    }

    const memberDetails = {
        'grid-spacing': 3,
        fields: [
            {
                'formGrid': {xs: 12, md: 12 },
                'isLabel': true,
                'formLabel': { htmlFor: "project-lead", required: true, label: 'Project Lead' },
                'outlinedInput': { id: "project-lead", name: "project-lead", type: "project-lead", placeholder: "@Project M @Added Blue backdrop", autoComplete: "project-lead", required: true, value: formData['project-lead'] }
            },
            {
                'formGrid': {xs: 12},
                'isLabel': false,
                'component': (handleChange) => {
                    return (
                        <Box sx = {{textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                            <FormLabel htmlFor={"version-delete-desc"} sx={{ marginBottom: '8px', borderRadius: '10px', textAlign: 'left' }} key={'version-delete-desc-formLabel'}>Project Members</FormLabel>
                            <CreatableSelect 
                                isMulti
                                onChange={(e) => handleChange({target: {name: "project-member", value: e.map(member => member.value)}})}
                                value={formData['project-member'].map(member => ({ value: member, label: member }))}
                                placeholder="Add member" 
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        borderRadius: '10px',
                                        backgroundColor: 'rgba(234, 240, 245, 0.4)'
                                    })
                                }}
                            />
                        </Box>
                    )
                }
            }
        ],
        actions: [],
        'style': {
            'formLabel': {
                marginBottom: '8px',
                borderRadius: '10px',
                textAlign: 'left',
            },
            'outlinedInput': {
                borderRadius: '10px',
                backgroundColor: 'rgba(234, 240, 245, 0.4)'
            }
        }
    }

    const adminSetting = {
        'grid-spacing': 3,
        fields: [
            {
                'formGrid': {xs: 12},
                'isLabel': false,
                'component': (handleChange) => {
                    return (
                        <Box display="flex" alignItems="center" sx = {{flexDirection: isXsScreen ? 'column' : 'row'}}>
                            <Box flex={1} sx = {{display: 'flex', flexDirection: 'column' }} >
                                <FormLabel htmlFor={"version-delete"} sx={{ borderRadius: '10px', textAlign: 'left', fontWeight: 'bold' }} key={'version-delete-formLabel'}>{"Versions Delete"}</FormLabel>
                                <FormLabel htmlFor={"version-delete-desc"} sx={{ marginBottom: '8px', borderRadius: '10px', textAlign: 'left' }} key={'version-delete-desc-formLabel'}>{"Deletes all versions in the Project"}</FormLabel>
                            </Box>
                            <Button 
                                variant="contained"
                                color="error" 
                                sx={{ 
                                    fontSize: '0.75rem'
                                }}
                                onClick={() => handleDeleteVersions()}
                                disabled={userInfo.signInDetails.loginId !== formData['project-lead']}
                            >
                                Delete Versions
                            </Button>
                        </Box>   
                    )
                }
            },
            {
                'formGrid': {xs: 12},
                'isLabel': false,
                'component': (handleChange) => {
                    return (
                        <Box display="flex" alignItems="center" sx = {{flexDirection: isXsScreen ? 'column' : 'row'}}>
                            <Box flex={1} sx = {{display: 'flex', flexDirection: 'column' }} >
                                <FormLabel htmlFor={"doc-delete"} sx={{ borderRadius: '10px', textAlign: 'left', fontWeight: 'bold' }} key={'doc-delete-formLabel'}>{"Notes & Docs Delete"}</FormLabel>
                                <FormLabel htmlFor={"doc-delete-desc"} sx={{ marginBottom: '8px', borderRadius: '10px', textAlign: 'left' }} key={'doc-delete-desc-formLabel'}>{"Deletes all notes & docs in the Project"}</FormLabel>
                            </Box>
                            <Button 
                                variant="contained" 
                                color="error"
                                sx={{ 
                                    fontSize: '0.75rem',
                                }}
                                onClick={() => handleDeleteNotesAndDocs()}
                                disabled={userInfo.signInDetails.loginId !== formData['project-lead']}
                            >
                                Delete Notes & Docs
                            </Button>
                        </Box>   
                    )
                }
            },
            {
                'formGrid': {xs: 12},
                'isLabel': false,
                'component': (handleChange) => {
                    return (
                        <Box display="flex" alignItems="center" sx = {{flexDirection: isXsScreen ? 'column' : 'row'}}>
                            <Box flex={1} sx = {{display: 'flex', flexDirection: 'column' }} >
                                <FormLabel htmlFor={"project-delete"} sx={{ borderRadius: '10px', textAlign: 'left', fontWeight: 'bold' }} key={'project-delete-formLabel'}>{"Project Delete"}</FormLabel>
                                <FormLabel htmlFor={"project-delete-desc"} sx={{ marginBottom: '8px', borderRadius: '10px', textAlign: 'left' }} key={'project-delete-desc-formLabel'}>{"This will delete the whole project"}</FormLabel>
                            </Box>
                            <Button 
                                variant="contained"
                                color="error" 
                                sx={{ 
                                    fontSize: '0.75rem'  
                                }}
                                onClick={() => handleDeleteProject()}
                                disabled={userInfo.signInDetails.loginId !== formData['project-lead']}
                            >
                                Delete Project
                            </Button>
                        </Box>   
                    )
                }
            }
        ],
        actions: [],
        'style': {
            'formLabel': {
                marginBottom: '8px',
                borderRadius: '10px',
                textAlign: 'left',
            },
            'outlinedInput': {
                borderRadius: '10px',
                backgroundColor: 'rgba(234, 240, 245, 0.4)'
            }
        }
    }

    return (
        <Box>
            {loading ? (<LoadingMask />) : (
                <Box>
                    <Box
                        sx= {{
                            marginLeft: '20%',
                            marginRight: '20%',
                            marginTop: '20px',
                            marginBlock: '20px'
                        }}
                    >
                        <Typography variant="normal" component="h2" sx={{ fontWeight: 'normal', textAlign: 'left' }}>
                            Project Details
                        </Typography>
                        < Divider sx ={{ marginTop: '5px', marginBottom: '15px' }} />
                        <InputFormNoAction grid={projectDetails} handleChange={handleChange} />
                    </Box>
                    <Box
                        sx= {{
                            marginLeft: '20%',
                            marginRight: '20%',
                            marginTop: '20px',
                            marginBlock: '20px'
                        }}
                    >
                        <Typography variant="normal" component="h2" sx={{ fontWeight: 'normal', textAlign: 'left' }}>
                            Member Details
                        </Typography>
                        < Divider sx ={{ marginTop: '5px', marginBottom: '15px' }} />
                        <InputFormNoAction grid={memberDetails} handleChange={handleChange} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column-reverse', sm: 'row' },
                            justifyContent: 'flex-start',
                            alignItems: 'end',
                            flexGrow: 1,
                            gap: 1,
                            marginLeft: '20%',
                            marginRight: '20%',
                            marginTop: '20px',
                            marginBottom: '40px',
                        }}
                    >
                        <Button
                            variant="contained"
                            // startIcon={action.startIcon}
                            // endIcon={action.endIcon}
                            key={'save-action'}
                            onClick={() => handleSubmit()}
                            disabled={userInfo.signInDetails.loginId !== formData['project-lead']}
                            sx={{
                                width: { xs: '100%', sm: 'fit-content' },
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                    <Box
                        sx= {{
                            marginLeft: '20%',
                            marginRight: '20%',
                            marginTop: '20px',
                            marginBottom: '20px',
                            border: '2px solid brown',
                            padding: '10px'
                        }}
                    >
                        <Typography variant="normal" component="h2" sx={{ fontWeight: 'normal', textAlign: 'left' }}>
                            Admin Settings
                        </Typography>
                        < Divider sx ={{ marginTop: '5px', marginBottom: '15px' }} />
                        <InputFormNoAction grid={adminSetting} handleChange={handleChange} />
                    </Box>
                    <SnackbarNotification snackbarOpen={snackbar.snackbarOpen} snackbarMessage={snackbar.snackbarMessage} setSnackbar={setSnackbar}/>
                </Box>
            )}
        </Box>
    )
};