import * as React from 'react';
import { Box } from '@mui/material';
import TabView from '../../primitives/tabs';
import LoadingMask from '../../primitives/LoadingMask';
import VersionHistory from './version-history/versionHistory';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getUrl } from 'aws-amplify/storage';
import { TaskDoc } from './tasks-and-doc/index.js';
import { Setting } from './setting/index.js';
import { listComments, listVersions } from '../../../../graphql/queries.js';
import { createVersion } from '../../../../graphql/mutations.js';
import { generateClient } from 'aws-amplify/api';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

const client = generateClient();
const tabs = [
  {
    value: 'task-and-doc',
    label: 'Tasks & Docs',
    icon: <TextSnippetIcon color='primary' />
  },
  {
    value: 'version-history',
    label: 'Version History',
    icon: <HistoryIcon color='primary' />
  },
  {
    value: 'project-setting',
    label: 'Project Setting',
    icon: <SettingsIcon color='primary' />
  }
  // {
  //   value: 'assets',
  //   label: 'Assets'
  // }
]
const defaultTab = 'version-history';

export default function Project(props) {
  const { id } = useParams();

  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(defaultTab);
  const [versionList, setVersionList] = React.useState([]);
  const [activeVersion, setActiveVersion] = React.useState({});

  // React.useEffect(() => {
  //   setLoading(true);
  //   fetchVersionsForProject()
  //   .then(() => {
  //     setLoading(false);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     setLoading(false);
  //   });
  // }, []);

  const handleActiveTabChange = (value) => {
    setActiveTab(value);
    console.log(value);
  }

  const fetchCommentForVersion = async (versionId) => {
    try {
      const commentData = await client.graphql({
        query: listComments,
        variables: {
          filter: {
            versionId: {
              eq: versionId
            }
          }
        }
      })
      return commentData.data.listComments.items;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const fetchVersionsForProject = async () => {
    let versionList = [];
    let activeVersion = {};
    try {
      const versionData = await client.graphql({
        query: listVersions,
        variables: {
          filter: {
            projectId: {
              eq: id
            }
          }
        }
      });
      versionList = versionData.data.listVersions.items;
      if (versionList.length > 0) {
        versionList.sort((a, b) => b.versionNo - a.versionNo);
        activeVersion = versionList[0];
        let commentList = await fetchCommentForVersion(activeVersion.id);
        activeVersion.comments = commentList;
        let outputURL = await fetchImgForOutput(activeVersion.outputFilePath);
        activeVersion.outputURL = outputURL;
      }
    } catch (error) {
      console.log(error);
    }
    setVersionList(versionList);
    setActiveVersion(activeVersion);
  }

  const fetchImgForOutput = async (imagePath) => {
    try {
      const imageUrl = await getUrl({
        path: imagePath,
      });
      return imageUrl.url;
    } catch (error) {
      console.log('Error ', error);
      return '';
    }
  }

  const addVersion = async (versionDetails) => {
    const versionData = await client.graphql({
      query: createVersion,
      variables: {
        input: {
          ...versionDetails,
          projectId: id,
          versionNo: versionList.length + 1
        }
      }
    });
    const version = versionData.data.createVersion;
    setVersionList([version, ...versionList]);

    let activeVersion = version;
    activeVersion.comments = [];
    let outputURL = await fetchImgForOutput(activeVersion.outputFilePath);
    activeVersion.outputURL = outputURL;
    setActiveVersion(activeVersion);
  }

  const onVersionClick =  async (versionId) => {
    let activeVersion = versionList.find(version => version.id === versionId);
    let commentList = await fetchCommentForVersion(versionId);
    activeVersion.comments = commentList;
    let outputURL = await fetchImgForOutput(activeVersion.outputFilePath);
    activeVersion.outputURL = outputURL;
    setActiveVersion(activeVersion);
  }

  const addComment = (comment) => {
    const newComments = [comment, ...activeVersion.comments];
    const newActiveVersion = { ...activeVersion, comments: newComments };
    setActiveVersion(newActiveVersion);
  }

  const deleteComment = (comment) => {
    const newComments = activeVersion.comments.filter(c => c.id !== comment.id);
    const newActiveVersion = { ...activeVersion, comments: newComments };
    setActiveVersion(newActiveVersion);
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'task-and-doc':
        return (
          <div>
            { loading ? (
              <Box sx={{ display: 'flex'}}>
                <Box component="main" sx={{ flexGrow: 1 }}>
                  <LoadingMask />
                </Box>
              </Box>
            ) : 
            ( <TaskDoc /> )}
          </div>
        );
      case 'project-setting':
        return (
          <div>
            { loading ? (
              <Box sx={{ display: 'flex'}}>
                <Box component="main" sx={{ flexGrow: 1 }}>
                  <LoadingMask />
                </Box>
              </Box>
            ) : 
            ( <Setting /> )}
          </div>
        )
      case 'version-history':
        return (
          <div>
            { loading ? (
              <Box sx={{ display: 'flex'}}>
                <Box component="main" sx={{ flexGrow: 1 }}>
                  <LoadingMask />
                </Box>
              </Box>
            ) : 
            ( <VersionHistory fetchVersionsForProject={fetchVersionsForProject} versionList={versionList} activeVersion={activeVersion} addVersion={addVersion} onVersionClick={onVersionClick} addComment={addComment} deleteComment={deleteComment} /> )}
          </div>
        );
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      <TabView defaultTab={defaultTab} tabsList={tabs} onClick={handleActiveTabChange} />
      {renderTabContent()}
    </Box>
  );
}