import React from 'react';
import Snackbar from '@mui/material/Snackbar';

export default function SnackbarNotification(props) {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        props.setSnackbar({snackbarOpen: false, snackbarMessage: ''});
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={props.snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            message={props.snackbarMessage}
            key={'updateMessage'}
        />
    )
}