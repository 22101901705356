import React, { Fragment} from 'react';
import DisplayProject from './display-component/index';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';


const columns = [
    { field: 'id', headerName: 'ID', width: 85 },
    { field: 'project', headerName: 'Existing Projects', flex: 1 }
];
  

export default function ProjectList(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is xs or smaller  
    
    const onProjectClick = (id) => {
        navigate(`/product/project/${id}`);
    } 

    const renderBigScreen = () => {
        return (
            <Grid container spacing={0} style={{ marginTop: '3rem', marginBottom: '3rem' }} sx={{ '& .MuiGrid-item': { paddingTop: '0px', paddingBottom: '0px' } }}>
                {props.projects.map((project, index) => (
                    <Grid item key={project.id} xs={12} sm={6} md={4} lg={3} sx={{ cursor: 'pointer', padding: '0px' }} onClick={() => onProjectClick(project.id)}>
                        <DisplayProject id={project.id} name={project.name} />
                    </Grid>
                ))}
            </Grid>
        );
    }

    const renderSmallScreen = () => {
        const rows = props.projects.map((project, index) => ({
            id: index+1,
            projectId: project.id,
            project: project.name,
        }));
        return (
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    onRowClick={(params) => onProjectClick(params.row.projectId)}
                    pageSizeOptions={[5, 10]}
                />
            </div>
        )
    }
    
    return (
        <Fragment>
            {isXsScreen ? renderSmallScreen() : renderBigScreen()}
        </Fragment>    
    )
}