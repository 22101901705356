import * as React from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));


export default function InputForm(props) {
    const grid = props.grid;
    const [formData, setFormData] = React.useState({});

    // Step 2: Create an onChange handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    return (
        <Grid container spacing={grid['grid-spacing']} >
            {grid.fields.map((field, index) => {
                return (
                    field.isLabel ? 
                    (
                        <FormGrid item xs={field.formGrid.xs} md={field.formGrid.md} key={index}>
                            <FormLabel htmlFor={field.formLabel.htmlFor} required={field.formLabel.required} sx={grid.style.formLabel} key={index+'-formLabel'}>{field.formLabel.label}</FormLabel>
                            <OutlinedInput
                                id={field.outlinedInput.id}
                                name={field.outlinedInput.name}
                                type={field.outlinedInput.type}
                                placeholder={field.outlinedInput.placeholder}
                                autoComplete={field.outlinedInput.autoComplete}
                                required={field.outlinedInput.required}
                                sx={grid.style.outlinedInput}
                                key={index+'-outlinedInput'}
                                onChange={handleChange}
                            />
                        </FormGrid>
                    ) : (
                        <FormGrid item xs={field.formGrid.xs} md={field.formGrid.md} key={index} >
                            {field.component(handleChange)}
                        </FormGrid>
                    )
                )
            })}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column-reverse', sm: 'row' },
                    justifyContent: 'center',
                    alignItems: 'end',
                    flexGrow: 1,
                    gap: 1,
                    pb: { xs: 12, sm: 0 },
                    mt: { xs: 2, sm: 2 },
                    mb: '60px',
                    marginLeft: '24px'
                }}
            >
                {grid.actions.map((action, index) => {
                    return (
                        <Button
                            variant="contained"
                            startIcon={action.startIcon}
                            endIcon={action.endIcon}
                            sx={{
                                width: { xs: '100%', sm: 'fit-content' },
                            }}
                            key={index+'-action'}
                            onClick={() => action.onClick(formData)}
                        >
                            {action.label}
                        </Button>
                    )
                })}
            </Box>
        </Grid>
    );
}