import React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SendTimeExtensionOutlinedIcon from '@mui/icons-material/SendTimeExtensionOutlined';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import NativeSelect from '@mui/material/NativeSelect';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position:'relative',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  position:'relative',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position:'relative',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function VersionBar(props) {
  const DrawerHeader = props.drawerHeader;

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderVersionBar = () => {
    return (
      <Drawer variant="permanent" open={props.open}>
        <DrawerHeader>
          {props.open ? (
            <IconButton onClick={props.closeBar}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton onClick={props.openBar}>
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />

        {props.open ? (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
              gap: 1.5,
              margin: '10px'
            }}
            onClick={() => props.addVersion()}
          >
            <Card
              key={'House'}
              sx={{
                boxShadow: 'none',
                '&:hover': { bgcolor: 'background.level1' },
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                <AddOutlinedIcon />
                <Typography level="title-md">Add New Version</Typography>
              </CardContent>
            </Card>
          </Box>
        ): (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '10px'
            }}
            onClick={() => props.addVersion()}
          >
            <IconButton>
              <AddOutlinedIcon />
            </IconButton>
          </Box>
        )}

        <List>
          { props.versionList.map((text, index) => (
            <ListItem key={text.id} disablePadding sx={{ display: 'block', backgroundColor: props.activeVersion.id === text.id ? 'lightgray' : undefined }} onClick={() => props.onVersionClick(text.id)} >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <SendTimeExtensionOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={"V" + text.versionNo + ": " + text.versionName} sx={{ opacity: props.open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    )
  }

  const renderSmallScreen = () => {
    return (
      <React.Fragment>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' , width: '100%', alignItems: 'center', gap:'20px'}}>
          <Card
            key={'House'}
            sx={{
              boxShadow: 'none',
              '&:hover': { bgcolor: 'background.level1' },
              width: '90%',
              // marginLeft: '10px',
              // marginRight: '10px',
              marginTop: '10px'
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => props.addVersion()}>
              <AddOutlinedIcon />
              <Typography level="title-md">Add New Version</Typography>
            </CardContent>
          </Card>
          {/* <DialogBox open={isFormVisible} onClose={toggleFormVisibility} title={"Add New Project"} children={<InputForm grid={grid} />} /> */}
          {props.versionList.length > 0 && (
            <FormControl sx = {{width: '90%'}} >
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Version History
              </InputLabel>
              <NativeSelect
                defaultValue={props.activeVersion.id}
                inputProps={{
                  name: 'age',
                  id: 'uncontrolled-native',
                }}
                onChange={(event) => {
                  const selectedVersionId = event.target.value;
                  props.onVersionClick(selectedVersionId);
                }}
              >
                {props.versionList.map((text, index) => (
                  <option key={text.id} value={text.id}>{"V" + text.versionNo + ": " + text.versionName}</option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
        </Box>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {isXsScreen ? renderSmallScreen() : renderVersionBar()}
    </React.Fragment>
  );
}