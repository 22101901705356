import React, { useEffect } from 'react';
import Version from './components/Version';
import VersionBar from './components/versionBar';
import CssBaseline from '@mui/material/CssBaseline';
import LoadingMask from '../../../primitives/LoadingMask';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import DialogBox from '../../../primitives/Dialog';
import InputForm from '../../../primitives/InputForm';
import LibraryAddRoundedIcon from '@mui/icons-material/LibraryAddRounded';
import CustomFileUpload from '../../../primitives/FileUpload';
import FolderUpload from '../../../primitives/FolderUpload';
import FormLabel from '@mui/material/FormLabel';
import { uploadData, list, getUrl } from 'aws-amplify/storage';
import { v4 as uuidv4 } from 'uuid';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import SnackbarNotification from '../../../primitives/Snackbar';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const zip = new JSZip();
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

  
export default function VersionHistory(props) {
    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState({ snackbarOpen: false, snackbarMessage: '' });

    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setLoading(true);
        props.fetchVersionsForProject()
        .then(() => {
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }, []);

    const grid = {
        'grid-spacing': 3,
        fields: [
            {
                'formGrid': {xs: 12, md: 12 },
                'isLabel': true,
                'formLabel': { htmlFor: "version-name", required: true, label: 'Version Name' },
                'outlinedInput': { id: "version-name", name: "version-name", type: "name", placeholder: "Matilda-07", autoComplete: "version name", required: true }
            },
            {
                'formGrid': {xs: 12, md: 12 },
                'isLabel': true,
                'formLabel': { htmlFor: "commit-message", required: true, label: 'Version Description' },
                'outlinedInput': { id: "commit-message", name: "commit-message", type: "commit-message", placeholder: "@Project M @Added Blue backdrop", autoComplete: "commit message", required: true }
            },           
            {
                'formGrid': {xs: 12},
                'isLabel': false,
                'component': (handleChange) => {
                    return (
                        <div style={{display: 'flex', justifyContent:'center', flexDirection: 'column'}}>
                            <FormLabel htmlFor="outfile-upload" sx={{marginBottom: '8px', borderRadius: '10px'}} key={'output-file'} required >{"Upload Final Output"}</FormLabel>    
                            <CustomFileUpload name={"output-file"} maxFileSize={1000000} multiple={false} acceptType={"image/*"} handleChange={handleChange} style={{ background: '#eaf0f5', opacity: 0.4, padding: '6px 12px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}/>
                        </div>
                    )
                }
            },
            {
                'formGrid': {xs: 12},
                'isLabel': false,
                'component': (handleChange) => {
                    return (
                        <div style={{display: 'flex', justifyContent:'center', flexDirection: 'column'}}>
                            <FormLabel htmlFor="folder-upload" sx={{marginBottom: '8px', borderRadius: '10px'}} key={'folder-file'} >{"Upload Project Files"}</FormLabel>    
                            <FolderUpload name={"folder-files"} handleChange={handleChange} style={{background: '#eaf0f5', backgroundColor: 'rgba(234, 240, 245, 0.4) !important', opacity: 0.4, padding: '6px 12px', border: 'none', borderRadius: '4px', cursor: 'pointer', width: '100%' }} />
                        </div>
                    )
                }
            }
        ],
        actions: [
            {
                'label': 'Submit',
                'startIcon': null,
                'endIcon': <LibraryAddRoundedIcon />,
                'onClick': (formData) => saveNewVersion(formData) 
            },
        ],
        'style': {
            'formLabel': {
                marginBottom: '8px',
                borderRadius: '10px',
            },
            'outlinedInput': {
                borderRadius: '10px',
                backgroundColor: 'rgba(234, 240, 245, 0.4)'
            }
        }
    }

    const saveOutputFile = async (formData) => {
        const objectURL = formData['output-file'].objectURL;
        const mimeType = formData['output-file'].type;
        try {
            // Convert the object URL to a Blob
            const response = await fetch(objectURL);
            const blob = await response.blob();
    
            // Calculate the SHA hash of the Blob data
            const arrayBuffer = await blob.arrayBuffer();
            const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer); // Use the SubtleCrypto API
            const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Convert bytes to hex string
            console.log(hashHex);
            
            const fileName = `public/output/${hashHex}.${mimeType.split('/')[1]}`;
            await uploadData({
                path: fileName, 
                // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
                data: blob,
                options: {
                    progressCallback(progress) {
                        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                        // Optionally, use the onProgress callback if you have it defined elsewhere
                        // onProgress(progress);
                      }, 
                }
            }).result;
            return fileName;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const saveFolderFiles = async (formData, versionId) => {
        const files = formData['folder-files'];
        if (files === undefined) return null;
        try {
            const folderPath = `public/projects/${versionId}`;
            for (const file of files) {
                let objectURL = URL.createObjectURL(file);
                let response = await fetch(objectURL);
                let blob = await response.blob();
                let filePath = file.webkitRelativePath;
                console.log("Uploading " + filePath);

                let fileName = `${folderPath}/${filePath}`;
                await uploadData({
                    path: fileName, 
                    data: blob,
                    options: {
                        progressCallback(progress) {
                            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                        }, 
                    }
                }).result;
            }
            return folderPath;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const saveNewVersion = async (formData) => {
        setDialogOpen(false);
        setLoading(true);
        console.log(formData);
        try {
            const versionId = uuidv4();
            const outputFileResult = await saveOutputFile(formData);
            console.log('Succeeded: ', outputFileResult);

            const folderFileResult = await saveFolderFiles(formData, versionId);
            console.log("Successfully uploaded all files", folderFileResult);

            const versionDetails = {
                id: versionId,
                versionName: formData['version-name'],
                commitMsg: formData['commit-message'],
                outputFilePath: outputFileResult,
                folderPath: folderFileResult !== null ? folderFileResult + "/" : "",
            }
            await props.addVersion(versionDetails);
            setLoading(false);
        } catch (error) {
            console.log('Error : ', error);
            setLoading(false);
        }
        setSnackbar({ snackbarOpen: true, snackbarMessage: 'New Version Created' })
    }

    const downloadProjectFiles = async () => {
        setSnackbar({ snackbarOpen: true, snackbarMessage: 'Downloading project files' })
        setLoading(true);
        const result = await list({
            path: props.activeVersion.folderPath,
        });
        const files = result.items;
        for (const file of files) {
            const urlData = await getUrl({
                path: file.path,
            });

            const response = await fetch(urlData.url);
            const blob = await response.blob();

            const path = file.path;
            const pathSegments = path.split('/').slice(3);
            let currentFolder = zip;

            for (let i = 0; i < pathSegments.length; i++) {
                const isLastSegment = i === pathSegments.length - 1;
                if (isLastSegment) {
                    // Last segment, add the file
                    currentFolder.file(pathSegments[i], blob);
                } else {
                    // Not the last segment, ensure folder exists
                    currentFolder = currentFolder.folder(pathSegments[i]);
                }
            }
        }
        console.log("zip generated");
        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, props.activeVersion.versionName);
        });
        setLoading(false);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const onVersionClick = async (versionId) => {
        setLoading(true);
        await props.onVersionClick(versionId);
        setLoading(false);
    }

    const addVersion = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: isXsScreen ? 'column' : 'row' }}>
            <CssBaseline />
            {loading ? <LoadingMask /> : (
                <>
                    <VersionBar open={open} closeBar={handleDrawerClose} openBar={handleDrawerOpen} versionList={props.versionList} drawerHeader={DrawerHeader} activeVersion={props.activeVersion} onVersionClick={onVersionClick} addVersion={addVersion} />

                    {props.versionList.length !== 0 && (
                        <Box component="main" sx={{ flexGrow: 1 }}>
                            <Version activeVersion={props.activeVersion} addComment={props.addComment} deleteComment={props.deleteComment} downloadProjectFiles={downloadProjectFiles} />
                        </Box>
                    )}
                </>
            )}
            <DialogBox open={dialogOpen} onClose={handleDialogClose} title={"Add New Version"} children={<InputForm grid={grid} />} />
            <SnackbarNotification snackbarOpen={snackbar.snackbarOpen} snackbarMessage={snackbar.snackbarMessage} setSnackbar={setSnackbar}/>
        </Box>
    )
}