import React from 'react';
import FolderOpenTwoToneIcon from '@mui/icons-material/FolderOpenTwoTone';
import Typography from '@mui/joy/Typography';

export default function DisplayProject(props) {
    
    return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '20px',
            // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
            // borderRadius: '8px',
            // border: '1px solid #ccc',
            width: 'fit-content',
          }}
        >
          <FolderOpenTwoToneIcon sx={{ fontSize: '125pt', color: '#1976D2', margin: '0px' }} />
          <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold', position: 'relative', bottom: '15px' }} level="title-md">
            {props.name}
          </Typography>
        </div>
    )
}