import React from 'react';
import { Box } from '@mui/material';
import Comment from '../../../../primitives/Comment';

const Activity = (props) => {
    return (
        <Box>
            {props.comments.map((comment, index) => (
                <Comment key={index} comment={comment} onEditComment={props.onEditComment} onDeleteComment={props.onDeleteComment} />
            ))}
        </Box>
    );
};

export default Activity;