import React from 'react';
import pako from 'pako';

export default function FolderUpload(props) {
    const onSelect = (e) => {
        console.log(e.target.files);
        const files = e.target.files;
        props.handleChange({target: {name: props.name, value: files}});
    }

    return (
        <div className="card flex justify-content-center file-upload-container" style={{ border: '1px solid black', borderRadius: '4px', backgroundColor: 'rgba(234, 240, 245, 0.4) !important', display: 'inline-block'}} >
            <input 
                type="file" 
                webkitdirectory="true" 
                directory="true" 
                onChange={onSelect} 
                multiple={true} 
                style={{...props.style}} 
            />
        </div>
    )


}