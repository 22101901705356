import React, {useState, useEffect} from "react";
import { DocsList } from "./components/DocsList";
import { useParams } from 'react-router-dom';
import LoadingMask from "../../../primitives/LoadingMask";
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { getUrl } from 'aws-amplify/storage';
import DocViewer from "./components/DocViewer";
import { listDocs, listNotes } from "../../../../../graphql/queries";
import { createDocs, updateNotes } from "../../../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import SnackbarNotification from "../../../primitives/Snackbar";

const client = generateClient();
export function TaskDoc(props) {
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState(null);
    const [docs, setDocs] = useState([]);
    const [notes, setNotes] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });

    useEffect(() => {
        setLoading(true);
        fetchDocsNotesForProject()
        .then(() => {
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
    }, []);

    const fetchDocsNotesForProject = async () => {
        let docsList = [];
        try {
            const docsData = await client.graphql({
                query: listDocs,
                variables: {
                    filter: {
                        projectId: {
                            eq: id
                        }
                    }
                }
            });
            docsList = docsData.data.listDocs.items;
        } catch (error) {
            console.log(error);
        }

        let noteList = [];
        try {
            const noteData = await client.graphql({
                query: listNotes,
                variables: {
                    filter: {
                        projectId: {
                            eq: id
                        },
                    }
                }
            });
            noteList = noteData.data.listNotes.items;
        } catch (error) {
            console.log(error);
        }
        setDocs(docsList);
        setNotes(noteList);
    }

    const fetchDocForViewer = async (filePath) => {
        try {
            console.log(filePath);
            const pdfUrl = await getUrl({
              path: filePath,
            });
            console.log(pdfUrl);
            let href = pdfUrl.url.href;
            const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(href)}&embedded=true`;
            console.log(googleDocsViewerUrl);
            return googleDocsViewerUrl;
          } catch (error) {
            console.log('Error ', error);
            return '';
          }
    };

    const addDoc = async (docDetails) => {
        try {
            const docData = await client.graphql({
                query: createDocs,
                variables: { 
                    input: {
                        ...docDetails,
                        id: uuidv4(),
                        projectId: id,
                    }
                }
            });
            const doc = docData.data.createDocs;
            let docBar = [doc, ...docs];

            setDocs(docBar);
        } catch (error) {
            console.log('Error : ', error);
        }
    }

    const toggleLoading = (value) => {
        setLoading(value);
    }

    const updateActiveTab = (tab) => {
        console.log(tab);
        setActiveTab(tab);
    }

    const onSaveNote = async (value) => {
        console.log(value);

        try {
            const noteData = await client.graphql({
                query: updateNotes,
                variables: { 
                    input: {
                        id: activeTab.id,
                        name: activeTab.name,
                        value: value,
                        projectId: id
                    }
                }
            });
            const updatedNotes = notes.map(note => 
                note.id === noteData.data.updateNotes.id ? noteData.data.updateNotes : note
            );
            setNotes(updatedNotes);
            setActiveTab(noteData.data.updateNotes);
            setSnackbar({ open: true, message: 'Note saved successfully' })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            { loading ? (
                <Box sx={{ display: 'flex'}}>
                    <Box component="main" sx={{ flexGrow: 1 }}>
                        <LoadingMask />
                    </Box>
                </Box>
            ) : (
                <>
                {activeTab == null ? (<DocsList projectId={id} docs={docs} notes={notes} addDoc={addDoc} setActiveTab={setActiveTab} toggleLoading={toggleLoading} />) : (<DocViewer barList={[...notes, ...docs]} activeTab={activeTab} setActiveTab={updateActiveTab} toggleLoading={toggleLoading} fetchDocForViewer={fetchDocForViewer} onSaveNote={onSaveNote} initialHtml={activeTab.value} />)}
                <SnackbarNotification snackbarOpen={snackbar.open} snackbarMessage={snackbar.message} setSnackbar={setSnackbar}/>
                </>
            )}
        </div>
    )
}