import { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import Button from '@mui/material/Button';
import { TextField, Avatar, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import "../../../styles/react-quill.scss";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered"},
            { list: "bullet"},
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image", "video"],
    ]
}


export default function CommentEditor(props) {
    const [value, setValue] = useState(props.note.value == '' || props.note.value == undefined ? '' : props.note.value);
    const [isExpanded, setIsExpanded] = useState(props.isExpanded);

    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Assuming you have a ref for the element you want to scroll to
    const myRef = useRef(null);

    const scrollToRef = () => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    // Call this function when isExpanded changes to true
    useEffect(() => {
        if (isExpanded && !props.isExpanded) {
            scrollToRef();
        }
    }, [isExpanded]);

    const saveComment = () => {
        console.log(value);
        props.onSaveComment(props.note, value);
        if (props.clearCommentOnSave) clearComment();
    }

    const updateValue = (value) => {
        console.log(value);
        setValue(value);
    }
    
    
    const clearComment = () =>{
        setValue(props.note.value);
        setIsExpanded(false || props.isExpanded);
    }

    return (
        <div ref={myRef}>
            
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'column', marginLeft: isXsScreen ? '10%':'20%', marginRight: isXsScreen ? '10%':'20%'}}>
                {isExpanded ? (
                    <div>
                        <ReactQuill 
                            theme='snow'
                            value={value}
                            onChange={updateValue}
                            modules={modules}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '0.8rem'}}>
                            <Button variant="contained" color="primary" style={{ marginRight: '1rem' }} onClick={saveComment}>
                            Save
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={clearComment}>
                            Cancel
                            </Button>
                        </div>
                    </div>
                ) : (
                    <TextField
                        fullWidth
                        multiline
                        rows={1}
                        variant="outlined"
                        size="small"
                        placeholder="Add a comment..."
                        onFocus={() => setIsExpanded(true)}
                        onBlur={() => setIsExpanded(false)}
                        sx = {{
                            marginLeft: isXsScreen ? '10%':'20%',
                            marginRight: isXsScreen ? '10%':'20%',
                            width: 'none',
                            padding:'0px',
                        }}
                    />
                )}
            </div>
        </div>   
    )
}