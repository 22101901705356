import React, { useState, useEffect } from 'react';
import SideBar from '../sidebar/index.js';
import Box from '@mui/material/Box';
import ProjectList from '../projects/allprojects/index.js';
import LoadingMask from '../primitives/LoadingMask.js';
import { createProjects, createNotes, updateMembers, createMembers } from '../../../graphql/mutations.js';
import { v4 as uuidv4 } from 'uuid';
import { generateClient } from 'aws-amplify/api';
import { listProjects, listMembers } from '../../../graphql/queries.js';
import { useSelector } from 'react-redux';
import SnackbarNotification from '../primitives/Snackbar.js';

const client = generateClient();
export default function HomePage(props){
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [memberInfo, setMemberInfo] = useState(null);
  const userInfo = useSelector(state => state.user);
  const [snackbar, setSnackbar] = useState({ snackbarOpen: false, snackbarMessage: '' });

  useEffect(() => {
      fetchProjects();
  },[]);
    
  const fetchProjects = async () => {
      let projectList = []
      setLoading(true);
      try {
          const memberData = await client.graphql({
            query: listMembers,
            variables: {
              filter: {
                email: {
                  eq: userInfo.signInDetails.loginId
                }
              }
            }
          });
          const members = memberData.data.listMembers.items;
          if (members.length !== 0) {
            setMemberInfo(members[0]);
            const projectIds = members[0].projects;
            for (const projectId of projectIds) {
              const projectData = await client.graphql({
                query: listProjects,
                variables: {
                  filter: {
                    id: {
                      eq: projectId
                    }
                  }
                }
              });
              projectList.push(projectData.data.listProjects.items[0]);
            }
          }
      } catch (err){
          console.log(err);
      }
      setProjectList(projectList);
      setLoading(false);
  }

    const handleFormSubmit = async (name, description) => {
      setLoading(true);
      if(name === '' || description === '') return;

      try {
        const projectData = await client.graphql({
          query: createProjects,
          variables: { 
            input: {
              id: uuidv4(),
              name: name,
              description: description,
              lead: userInfo.signInDetails.loginId,
              members: [userInfo.signInDetails.loginId]
            } 
          }
        })
        const project = projectData.data.createProjects;
      
        // Add the project to the user
        if (memberInfo) {
          await client.graphql({
            query: updateMembers,
            variables: { 
              input: {
                id: memberInfo.id,
                name: memberInfo.name,
                email: memberInfo.email,
                projects: [...memberInfo.projects, project.id]
              }
            }
          })
        } else {
          await client.graphql({
            query: createMembers,
            variables: { 
              input: {
                id: uuidv4(),
                name: userInfo.signInDetails.loginId,
                email: userInfo.signInDetails.loginId,
                projects: [project.id]
              }
            }
          })
        }

        // Add the default note to the project
        await client.graphql({
          query: createNotes,
          variables: { input: {
              id: uuidv4(),
              name: 'Note',
              value: '',
              projectId: project.id
            }
          }
      });
      } catch (error) {
        console.log(error);
      }
      setSnackbar({snackbarOpen: true, snackbarMessage: 'Project created successfully!'});
      fetchProjects();
    }

    return(
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['100%', '23rem minmax(0, calc(100% - 25rem))'],
            width: '100%',
          }}
        >
          <SideBar handleFormSubmit={handleFormSubmit}/>
          {loading ? <LoadingMask /> : <ProjectList projects={projectList}/>}
          <SnackbarNotification snackbarOpen={snackbar.snackbarOpen} snackbarMessage={snackbar.snackbarMessage} setSnackbar={setSnackbar}/>
        </Box>
    )
}