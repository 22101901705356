import React, { Fragment } from 'react';
import { ReactComponent as Notes } from "../../../../../../assets/Notes.svg";
import { ReactComponent as DocAdd } from "../../../../../../assets/DocAdd.svg";
import { ReactComponent as Doc } from "../../../../../../assets/Doc.svg";
import Box from '@mui/material/Box';
import DocDisplay from './DocDisplay';
import CustomFileUpload from '../../../../primitives/FileUpload';
import { uploadData } from 'aws-amplify/storage';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "@mui/material";

const NotesIcon = (props) => {
    return (
        <Box sx={{...props.sx}} >
            <Notes />
        </Box>
    );
}

const DocIcon = (props) => {
    return (
        <Box sx={{...props.sx}} >
            <Doc />
        </Box>
    );
}

const DocAddIcon = (props) => {
    return (
        <Box sx={{...props.sx}} >
            <DocAdd />
        </Box>
    );
}

const noteColumns = [
    { field: 'id', headerName: 'ID', width: 85 },
    { field: 'items', headerName: 'Note Items', flex: 1 }
];

const docColumns = [
    { field: 'id', headerName: 'ID', width: 85 },
    { field: 'items', headerName: 'Doc Items', flex: 1 }
];

export function DocsList(props) {
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const onDocUpload = async (e) => {
        props.toggleLoading(true);
        const { _ , value } = e.target;
        console.log(value);
        const objectURL = value.objectURL;
        const mimeType = value.type;
        const name = value.name;
        try {
            // Convert the object URL to a Blob
            const response = await fetch(objectURL);
            const blob = await response.blob();
    
            // Calculate the SHA hash of the Blob data
            const arrayBuffer = await blob.arrayBuffer();
            const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer); // Use the SubtleCrypto API
            const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Convert bytes to hex string
            console.log(hashHex);
            
            const fileName = `public/docs/${hashHex}.${mimeType.split('/')[1]}`;
            const result = await uploadData({
                path: fileName, 
                // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
                data: blob,
                options: {
                    progressCallback(progress) {
                        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                        // Optionally, use the onProgress callback if you have it defined elsewhere
                        // onProgress(progress);
                        }, 
                }
            }).result;
            console.log('Succeeded: ', result);
            const docDetails = {
                name: name,
                file: fileName,
            }
            await props.addDoc(docDetails);
        } catch (error) {
            console.log('Error : ', error);   
        }
        props.toggleLoading(false);
    };

    const renderBigScreen = () => {
        return (
            <div style={{
                position: 'relative',
                marginTop: '3rem',
                marginBottom: '3rem',
                display: 'flex',
                flexWrap: 'wrap',
                // gap: '40px',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // Adjust based on screen size
                // justifyContent: 'space-evenly',
            }}>
                { props.notes.map((note, index) => {
                    return (
                        <DocDisplay key={index} id={index} tab={note} icon={(sx) => { return(<NotesIcon sx={sx}/>) }} setActiveTab={props.setActiveTab} />
                    );
                })}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                        width: 'fit-content',
                        cursor: 'pointer',
                        position: 'relative', // Establish this div as a positioning context
                    }}
                >
                    <div style={{ position: 'absolute', inset: 'none', top:'40px', opacity: 0, width: '200px'}}>
                        <CustomFileUpload maxFileSize={1000000} acceptType={"application/pdf"} multiple={false} handleChange={onDocUpload} style={{ background: '#eaf0f5', opacity: 0.4, padding: '6px 12px', border: 'none', borderRadius: '4px', cursor: 'pointer', width: '160px', height: '260px', display: 'flex', justifyContent: 'center'}} />
                    </div>
                    <DocAddIcon sx={{ fontSize: '125pt', color: '#1976D2', margin: '0px' }} />
                </div>
                { props.docs.map((doc, index) => {
                    return (
                        <DocDisplay key={index} id={index} tab={doc} icon={(sx) => { return(<DocIcon sx={sx}/>) }} setActiveTab={props.setActiveTab} />
                    );
                })}
            </div>
        )
    }

    const renderSmallScreen = () => {
        const notes = props.notes.map((note, index) => ({
            id: index+1,
            itemId: note.id,
            items: note.name,
            tab: note
        }));

        const docs = props.docs.map((doc, index)  => ({
            id: index+1,
            itemId: doc.id,
            items: doc.name,
            tab: doc
        }));
        return (
            <div style={{ width: '100%' }}>
                <DataGrid
                    rows={[...notes]}
                    columns={noteColumns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    onRowClick={(params) => props.setActiveTab(params.row.tab)}
                    pageSizeOptions={[5, 10]}
                />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                        width: 'fit-content',
                        cursor: 'pointer',
                        position: 'relative', // Establish this div as a positioning context
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary" 
                        sx={{
                            fontSize: '0.75rem'
                        }}
                    >
                        Add PDF
                    </Button>
                    <div style={{ position: 'absolute', inset: 'none', opacity: 0, width: '200px'}}>
                        <CustomFileUpload maxFileSize={1000000} acceptType={"application/pdf"} multiple={false} handleChange={onDocUpload} style={{ background: '#eaf0f5', opacity: 0.4, padding: '6px 12px', border: 'none', borderRadius: '4px', cursor: 'pointer', width: '85px', display: 'flex', justifyContent: 'center'}} />
                    </div>
                </div>

                <DataGrid
                    rows={[...docs]}
                    columns={docColumns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    onRowClick={(params) => props.setActiveTab(params.row.tab)}
                    pageSizeOptions={[5, 10]}
                />
            </div>
        )
    }

    return (
        <Fragment>
            {isXsScreen ? renderSmallScreen() : renderBigScreen()}
        </Fragment> 
    );
}