import React,{ useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

export default function TabView(props) {
  const [value, setValue] = useState(props.defaultTab);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onClick(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
        <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx ={{borderBottom: 'solid 0.4px grey'}}
        >
            {props.tabsList.map((tab, idx) => {
              return (
                <Tooltip key={tab.value} title={tab.label}>
                  <Tab 
                    key={tab.value} 
                    value={tab.value} 
                    label={isXsScreen ? null : tab.label} 
                    icon={isXsScreen ? tab.icon : null}
                    // Ensure icon is only shown on xs screens, otherwise, it's hidden but still provides the label for accessibility
                    sx={{ '.MuiTab-iconWrapper': { display: isXsScreen ? 'block' : 'none' }, '.MuiTab-wrapper': { flexDirection: 'row' } }}
                  />
                </Tooltip>
              );
            })}
        </Tabs>
    </Box>
  );
}