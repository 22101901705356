import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));


export default function InputFormNoAction(props) {
    const grid = props.grid;
    
    return (
        <Grid container spacing={grid['grid-spacing']} >
            {grid.fields.map((field, index) => {
                return (
                    field.isLabel ? 
                    (
                        <FormGrid item xs={field.formGrid.xs} md={field.formGrid.md} key={index}>
                            <FormLabel htmlFor={field.formLabel.htmlFor} required={field.formLabel.required} sx={grid.style.formLabel} key={index+'-formLabel'}>{field.formLabel.label}</FormLabel>
                            <OutlinedInput
                                id={field.outlinedInput.id}
                                name={field.outlinedInput.name}
                                type={field.outlinedInput.type}
                                placeholder={field.outlinedInput.placeholder}
                                autoComplete={field.outlinedInput.autoComplete}
                                required={field.outlinedInput.required}
                                sx={grid.style.outlinedInput}
                                key={index+'-outlinedInput'}
                                onChange={props.handleChange}
                                value={field.outlinedInput.value}
                            />
                        </FormGrid>
                    ) : (
                        <FormGrid item xs={field.formGrid.xs} md={field.formGrid.md} key={index} >
                            {field.component(props.handleChange)}
                        </FormGrid>
                    )
                )
            })}
        </Grid>
    );
}