import React from 'react';
import Typography from '@mui/joy/Typography';

export default function DocDisplay(props) {

    const onTabClick = () => {
        props.setActiveTab(props.tab);
    }
    
    return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            padding: '20px',
            // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
            // borderRadius: '8px',
            // border: '1px solid #ccc',
            width: 'fit-content',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={onTabClick}
        >
          {props.icon({ color: '#1976D2', margin: '0px' })}
          <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold', position: 'relative', bottom: '15px' }} level="title-md">
            {props.tab.name}
          </Typography>
        </div>
    )
}