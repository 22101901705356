import './App.css';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Product from './component/product/index';
import { Provider } from 'react-redux';
import store from './component/store/store';
import LandingPage from './component/landing/LandingPage';

Amplify.configure(config);

const App = () => {

  return (
    <Router>
      <div className="App">
        <Routes>
        <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/product/*" element={
            <Provider store={store}>
              <Product />
            </Provider>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
