/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProjects = /* GraphQL */ `
  mutation CreateProjects(
    $input: CreateProjectsInput!
    $condition: ModelProjectsConditionInput
  ) {
    createProjects(input: $input, condition: $condition) {
      id
      name
      description
      lead
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProjects = /* GraphQL */ `
  mutation UpdateProjects(
    $input: UpdateProjectsInput!
    $condition: ModelProjectsConditionInput
  ) {
    updateProjects(input: $input, condition: $condition) {
      id
      name
      description
      lead
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProjects = /* GraphQL */ `
  mutation DeleteProjects(
    $input: DeleteProjectsInput!
    $condition: ModelProjectsConditionInput
  ) {
    deleteProjects(input: $input, condition: $condition) {
      id
      name
      description
      lead
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMembers = /* GraphQL */ `
  mutation CreateMembers(
    $input: CreateMembersInput!
    $condition: ModelMembersConditionInput
  ) {
    createMembers(input: $input, condition: $condition) {
      id
      name
      email
      status
      projects
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMembers = /* GraphQL */ `
  mutation UpdateMembers(
    $input: UpdateMembersInput!
    $condition: ModelMembersConditionInput
  ) {
    updateMembers(input: $input, condition: $condition) {
      id
      name
      email
      status
      projects
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMembers = /* GraphQL */ `
  mutation DeleteMembers(
    $input: DeleteMembersInput!
    $condition: ModelMembersConditionInput
  ) {
    deleteMembers(input: $input, condition: $condition) {
      id
      name
      email
      status
      projects
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVersion = /* GraphQL */ `
  mutation CreateVersion(
    $input: CreateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    createVersion(input: $input, condition: $condition) {
      id
      versionName
      commitMsg
      outputFilePath
      folderPath
      projectId
      versionNo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVersion = /* GraphQL */ `
  mutation UpdateVersion(
    $input: UpdateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    updateVersion(input: $input, condition: $condition) {
      id
      versionName
      commitMsg
      outputFilePath
      folderPath
      projectId
      versionNo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVersion = /* GraphQL */ `
  mutation DeleteVersion(
    $input: DeleteVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    deleteVersion(input: $input, condition: $condition) {
      id
      versionName
      commitMsg
      outputFilePath
      folderPath
      projectId
      versionNo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      comment
      versionId
      userName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      comment
      versionId
      userName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      comment
      versionId
      userName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocs = /* GraphQL */ `
  mutation CreateDocs(
    $input: CreateDocsInput!
    $condition: ModelDocsConditionInput
  ) {
    createDocs(input: $input, condition: $condition) {
      id
      name
      file
      projectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocs = /* GraphQL */ `
  mutation UpdateDocs(
    $input: UpdateDocsInput!
    $condition: ModelDocsConditionInput
  ) {
    updateDocs(input: $input, condition: $condition) {
      id
      name
      file
      projectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocs = /* GraphQL */ `
  mutation DeleteDocs(
    $input: DeleteDocsInput!
    $condition: ModelDocsConditionInput
  ) {
    deleteDocs(input: $input, condition: $condition) {
      id
      name
      file
      projectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotes = /* GraphQL */ `
  mutation CreateNotes(
    $input: CreateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    createNotes(input: $input, condition: $condition) {
      id
      name
      value
      projectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotes = /* GraphQL */ `
  mutation UpdateNotes(
    $input: UpdateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    updateNotes(input: $input, condition: $condition) {
      id
      name
      value
      projectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotes = /* GraphQL */ `
  mutation DeleteNotes(
    $input: DeleteNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    deleteNotes(input: $input, condition: $condition) {
      id
      name
      value
      projectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFeatureSuggestion = /* GraphQL */ `
  mutation CreateFeatureSuggestion(
    $input: CreateFeatureSuggestionInput!
    $condition: ModelFeatureSuggestionConditionInput
  ) {
    createFeatureSuggestion(input: $input, condition: $condition) {
      id
      suggestion
      votes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFeatureSuggestion = /* GraphQL */ `
  mutation UpdateFeatureSuggestion(
    $input: UpdateFeatureSuggestionInput!
    $condition: ModelFeatureSuggestionConditionInput
  ) {
    updateFeatureSuggestion(input: $input, condition: $condition) {
      id
      suggestion
      votes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFeatureSuggestion = /* GraphQL */ `
  mutation DeleteFeatureSuggestion(
    $input: DeleteFeatureSuggestionInput!
    $condition: ModelFeatureSuggestionConditionInput
  ) {
    deleteFeatureSuggestion(input: $input, condition: $condition) {
      id
      suggestion
      votes
      createdAt
      updatedAt
      __typename
    }
  }
`;
