import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  username: undefined, userId: undefined, signInDetails: undefined
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser(state, action) {
      const { username, userId, signInDetails } = action.payload;
      state.username = username;
      state.userId = userId;
      state.signInDetails = signInDetails;
    }
  }
})

export const { updateUser } = userSlice.actions;
export default userSlice.reducer;