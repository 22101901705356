import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { Typography } from '@mui/material';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

// Style a div to contain the blinking text
const BlinkingText = styled("div")({
  fontSize: "25px", // Set the font size for the text
  animation: `${blink} 2s linear infinite`,
  color: '#1976d2',
  display: 'flex',
  gap: '8px',
});

function LoadingMask() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <BlinkingText>
        <CircularProgress size={25}/>
        <Typography>Please wait...</Typography>
        </BlinkingText>
    </Box>
  );
}

export default LoadingMask;