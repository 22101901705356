import React from 'react';
import { Avatar, Button, Typography, CardContent, CardActions} from '@mui/material';
import DOMPurify from 'dompurify';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const style = 
`<style> 
    .sanitized-content * {
        margin: 0px !important;
        width: 95% !important;
    }
</style>`;

const Comment = (props) => {
    const sanitizedHtml = style + DOMPurify.sanitize(props.comment.comment);
    const commentDate = new Date(props.comment.createdAt);
    const formatMonth = (month) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[month];
    };

    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const formattedDate = isXsScreen ? `${commentDate.getDate()} ${formatMonth(commentDate.getMonth())} ${commentDate.getFullYear().toString().slice(-2)}` : `${commentDate.getDate()} ${formatMonth(commentDate.getMonth())} ${commentDate.getFullYear()} ${((commentDate.getHours() + 11) % 12 + 1)}:${commentDate.getMinutes().toString().padStart(2, '0')} ${commentDate.getHours() >= 12 ? 'PM' : 'AM'}`;

    return (
        <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <CardContent sx={{padding:'0px', display: 'flex', justifyContent: 'left', marginLeft: isXsScreen ? '10%':'20%', marginRight: isXsScreen ? '10%':'20%', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: '#1976d2', width: 30, height: 30, fontSize: '0.90rem' }}>
                        {props.comment.userName.charAt(0).toUpperCase()}
                    </Avatar>
                    <Typography variant="body2" color="text.secondary" sx={{ paddingLeft: '10px', paddingRight: '0px', textAlign: 'left', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                        {props.comment.userName} <span style={{marginLeft: '10px', fontSize: '0.8rem', fontWeight: 'normal' }}>{formattedDate}</span>
                    </Typography>
            </CardContent>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '5px', flexDirection: 'column', marginLeft: isXsScreen ? '10%':'20%', marginRight: isXsScreen ? '10%':'20%', position: 'relative', left: '40px'}}> 
                <div
                    className="sanitized-content"
                    style={{textAlign: 'left'}}
                    dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                />
                <CardActions sx={{padding: '0px', margin: '0px',}}>
                {/* <Button 
                    variant="text"
                    sx={{
                        padding: '0px',
                        margin: '0px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        minWidth: '0px',
                        color: 'black',
                    }}
                    onClick={() => props.onEditComment(props.comment)}
                > Edit </Button> */}
                <Button 
                    variant="text"
                    sx={{
                        padding: '0px',
                        margin: '0px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        minWidth: '0px',
                        color: 'black',
                    }}
                    onClick={() => props.onDeleteComment(props.comment)}
                > Delete </Button>
                </CardActions>
            </div>
        </div>
    );
};

export default Comment;