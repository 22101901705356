import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import PdfViewer from '../../../../primitives/PdfViewer';
import DocBar from './DocBar.js';
import LexicalWrapper from '../../../../primitives/lexical/LexicalWrapper.js';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

  
export default function DocViewer(props) {
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <Box sx={{ display: 'flex', height: '100vh', flexDirection: isXsScreen ? 'column' : 'row'}}>
            <CssBaseline />
            <DocBar open={open} closeBar={handleDrawerClose} openBar={handleDrawerOpen} barList={props.barList} drawerHeader={DrawerHeader} activeTab={props.activeTab} setActiveTab={props.setActiveTab} />
                    
            <Box component="main" sx={{ flexGrow: 1 }}>
                {props.activeTab.file !== undefined ? (
                    <PdfViewer activeDoc={props.activeTab} useEffectFunc={props.fetchDocForViewer} />
                ) : (
                    <div style={{margin: '10px', marginTop: '20px'}}>
                        {/* <CommentEditor onSaveComment={props.onSaveNote} isExpanded={true} note={props.activeTab} clearCommentOnSave={false} /> */}
                        <LexicalWrapper onSave={props.onSaveNote} initialHtml={props.initialHtml} />                     
                    </div>
                )}
            </Box>
        </Box>
    )
}