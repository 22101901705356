import React, { useState, useEffect } from "react";
import HeaderComponent from './header/index.js';
import { getCurrentUser } from 'aws-amplify/auth';
import { withAuthenticator} from "@aws-amplify/ui-react";
import HomePage from './home/index';
import Project from './projects/project/index';
import { Routes, Route, useParams } from 'react-router-dom';
import { updateUser } from "./redux/userSlice.js";
import LoadingMask from "./primitives/LoadingMask.js";
import { useDispatch } from 'react-redux';
import "@aws-amplify/ui-react/styles.css";
import QuickDial from "./quickdial/QuickDial.js";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const Product = ({ signOut }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const dispatch = useDispatch();

    const assesLoggedInState = async () => {
      try {
        const { username, userId, signInDetails } = await getCurrentUser();
        console.log('Current user:', username);
        dispatch(updateUser({ username, userId, signInDetails }));
        setLoggedIn(true);
      } catch (error) {
        console.log("Error", error);
        setLoggedIn(false);
      }
    }
      
    useEffect(() => {
        assesLoggedInState()
    }, []);
  
    return (
       <div className="App">
            <HeaderComponent signOut={signOut} loggedIn={loggedIn}/>
            {!loggedIn ? <LoadingMask /> : (
              <Routes>
                <Route 
                  path="/" 
                  element={<HomePage />}
                />
                <Route
                  path="/project/:id" 
                  element={<ProjectPage />} 
                />
              </Routes>
            )}
            <QuickDial />
       </div>
    )
}

function ProjectPage() {
  const { id } = useParams();
  return <Project id={id} />;
}


export default withAuthenticator(Product);