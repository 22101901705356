import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Card, CardMedia, Dialog, IconButton, Box } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function ImageViewer(props) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card sx={{ marginLeft: isXsScreen ? '10%': '20%', marginRight: isXsScreen ? '10%': '20%', '&:hover': { cursor: 'pointer' }}}>
            <Box sx={{ position: 'relative', height: isXsScreen ? '250px': '400px' }}>
              <CardMedia
                sx={{ height: '100%', width: '100%', objectFit: isXsScreen ? 'contain' : 'cover' }}
                image={props.image.href}
                title={props.altText}
                component="img"
                onClick={handleClickOpen}
              />
              <IconButton
                sx={{ position: 'absolute', top: 0, right: 0, color: '#1976d2', zIndex: 2, cursor: 'click' }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the CardMedia onClick from firing
                  // Logic to download the image
                  const link = document.createElement('a');
                  link.href = props.image;
                  document.body.appendChild(link); // Append to the document
                  link.click(); // Trigger click programmatically
                  document.body.removeChild(link);
                }}
              >
                <DownloadIcon />
              </IconButton>
            </Box>
            <Dialog
              open={open}
              onClose={handleClose}
              onClick={handleClose}
            >
              <img
                src={props.image.href}
                alt={props.altText}
                style={{ width: '100%' }}
              />
            </Dialog>
        </Card>
    );
}